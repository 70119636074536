import { computed, ref, watch } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import store from '@/store';

export default function useProfilesList() {
  // Use toast
  const toast = useToast();

  const refProfileListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, stickyColumn: true, variant: 'primary' },
    { key: 'rep' },
    { key: 'pathway', sortable: true, label: 'Pathway/Subject' },
    { key: 'session', sortable: true },
    { key: 'uploadedResume', label: 'Resume', sortable: true },
    { key: 'uploadedCoe', label: 'COE', sortable: true },
    { key: 'submittedToVa', label: 'Submission', sortable: true },
    { key: 'fundingSource', label: 'Funding', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];

  if (router.currentRoute.query.subject) {
    tableColumns[2] = { key: 'subject', sortable: true };
  }

  const {
    pathway: qPathway,
    subject: qSubject,
    search: qSearch,
    enrolled: qEnrolled,
    visibility: qVisibility,
    status: qStatus,
    funding: qFunding,
    resume: qResume,
    coe: qCoe,
    va: qVa,

    foresight: qForesight,

    perPage: qPerPage,
    page: qCurrentPage,
    sortBy: qSortBy,
    sortDesc: qSortDesc,
  } = router.currentRoute.query;

  const perPage = ref(qPerPage || 10);
  const totalProfiles = ref(0);
  const currentPage = ref(qCurrentPage || 1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref(qSearch);
  const sortBy = ref(qSortBy || 'createdAt');
  const isSortDirDesc = ref(qSortDesc || true);

  const visibilityFilter = ref(qVisibility);
  const statusFilter = ref(qStatus);
  const foresightFilter = ref(qForesight);
  const includeEnrolledFilter = ref(qEnrolled || 'no');
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);
  const pathwayFilter = ref(qPathway);
  const subjectFilter = ref(qSubject);
  const fundingFilter = ref(qFunding);
  const resumeFilter = ref(qResume);
  const coeFilter = ref(qCoe);
  const submissionFilter = ref(qVa);

  const dataMeta = computed(() => {
    const localItemsCount = refProfileListTable.value ? refProfileListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProfiles.value,
    };
  });

  const refetchData = () => {
    refProfileListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      includeEnrolledFilter,
      statusFilter,
      createAtStartFilter,
      createAtEndFilter,
      pathwayFilter,
      fundingFilter,
      resumeFilter,
      coeFilter,
      submissionFilter,
      foresightFilter,
      sortBy,
      isSortDirDesc,
    ],
    () => {
      refetchData();

      const query = {
        search: searchQuery.value,
        pathway: pathwayFilter.value,
        subject: subjectFilter.value,

        enrolled: includeEnrolledFilter.value,
        status: statusFilter.value,
        funding: fundingFilter.value,
        resume: resumeFilter.value,
        coe: coeFilter.value,
        va: submissionFilter.value,

        foresight: foresightFilter.value,

        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      };

      if (!query.search) delete query.search;
      if (!query.status) delete query.status;
      if (!query.enrolled) delete query.enrolled;
      if (!query.funding) delete query.funding;
      if (!query.resume) delete query.resume;
      if (!query.coe) delete query.coe;
      if (!query.va) delete query.va;

      if (query.page === '1' || query.page === 1) delete query.page;
      if (query.perPage === '10' || query.perPage === 10) delete query.perPage;
      if (query.sortBy === 'createdAt') delete query.sortBy;
      if (query.sortDesc === 'true' || query.sortDesc === true) delete query.sortDesc;

      router.replace({
        query,
      });
    }
  );

  const fetchProfiles = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'student,session,pathway,subject,representative',
    };

    if (foresightFilter.value) {
      console.log(`applying foresight ${foresightFilter.value}`);
      pl.foresight = Number(foresightFilter.value);
    }

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    if (includeEnrolledFilter.value === 'no') {
      pl.session = 'null';
    }
    if (createAtStartFilter.value) {
      pl.createAtStart = createAtStartFilter.value;
    }
    if (createAtEndFilter.value) {
      pl.createAtEnd = createAtEndFilter.value;
    }
    if (pathwayFilter.value) {
      pl.pathway = pathwayFilter.value;
    }
    if (subjectFilter.value) {
      pl.subject = subjectFilter.value;
    }

    if (fundingFilter.value) {
      pl.fundingSource = fundingFilter.value;
    }

    if (resumeFilter.value) {
      pl.uploadedResume = resumeFilter.value;
    }
    if (coeFilter.value) {
      pl.uploadedCoe = coeFilter.value;
    }
    if (submissionFilter.value) {
      pl.submittedToVa = submissionFilter.value;
    }

    /*
    resumeFilter,
    coeFilter,
    submissionFilter, */

    store
      .dispatch('app-roster/fetchProfiles', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalProfiles.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching profiles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'primary';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'ProfileIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'ProfileIcon';
  };

  const resolveProfileVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolveProfileStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'pending') return 'danger';
    if (status === 'action_required') return 'primary';
    if (status === 'enrolled') return 'info';
    if (status === 'withdrew') return 'warning';
    if (status === 'terminated') return 'danger';
    if (status === 'graduated') return 'success';
    return 'primary';
  };

  const resolveProfileProgramVariant = (status) => {
    if (status === 'open') return 'success';
    if (status === 'vettec') return 'info';
    if (status === 'vrrap') return 'primary';
    if (status === 'wioa') return 'warning';
    return 'secondary';
  };

  const resolveProfileYNVariant = (status) => {
    if (status === 'yes') return 'success';
    if (status === 'no') return 'danger';
    return 'warning';
  };

  const resolveProfilePaymentStatusVariant = (status) => {
    if (status === 'unpaid') return 'danger';
    if (status === 'partially_paid') return 'warning';
    if (status === 'payment_plan') return 'info';
    if (status === 'paid') return 'success';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Pending', value: 'pending' },
    { label: 'Action Required', value: 'action_required' },
    { label: 'Enrolled', value: 'enrolled' },
    { label: 'Withdrawal (before program start)', value: 'withdrew_s1' },
    { label: 'Withdrawal (during withdrawal period)', value: 'withdrew_s2' },
    { label: 'Withdrawal (after withdrawal period)', value: 'withdrew_s3' },
    { label: 'Terminated', value: 'terminated' },
    { label: 'Graduated', value: 'graduated' },
    { label: 'Denial of Benefits', value: 'denial_of_benefit' },
    { label: 'Archived', value: 'archived' },
  ];

  const foresightOptions = [
    { label: 'Unlimited', value: null },
    { label: '+1 week', value: '7' },
    { label: '+2 weeks', value: '14' },
    { label: '+1 month', value: '30' },
    { label: '+3 months', value: '90' },
  ];

  const courseStatusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Enrolled', value: 'enrolled' },
    { label: 'Completed', value: 'completed' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Student', value: 'student' },
  ];

  const fundingSourceOptions = [
    { label: 'Open', value: 'open' },
    { label: 'VRRAP', value: 'vrrap' },
    { label: 'VET-TEC', value: 'vettec' },
    { label: 'WIOA', value: 'wioa' },
    { label: 'GI Bill', value: 'gi_bill' },
    { label: 'Army COOL', value: 'army_cool' },
    { label: 'AirForce COOL', value: 'airforce_cool' },
    { label: 'MYCAA', value: 'mycaaa' },
    { label: 'VR&E', value: 'vrne' },
    { label: 'Corporate', value: 'corp' },
  ];

  const paymentStatusOptions = [
    { label: 'Unpaid', value: 'unpaid' },
    { label: 'Partially Paid', value: 'partially_paid' },
    { label: 'Payment Plan', value: 'payment_plan' },
    { label: 'Paid', value: 'paid' },
  ];

  const submittedToVaOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const uploadedCoeOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const uploadedResumeOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const examVoucherOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'Pending', value: 'pending' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const yesNoOptions = [
    { label: 'N/A', value: 'na' },
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
  ];

  const locationOptions = [
    { label: 'Virtual', value: 'virtual' },
    { label: 'Physical', value: 'physical' },
  ];

  return {
    fetchProfiles,
    tableColumns,
    perPage,
    currentPage,
    totalProfiles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProfileListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveProfileVisibilityVariant,
    resolveProfileStatusVariant,

    resolveProfileProgramVariant,
    resolveProfileYNVariant,
    resolveProfilePaymentStatusVariant,

    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
    foresightFilter,
    includeEnrolledFilter,
    createAtStartFilter,
    createAtEndFilter,
    pathwayFilter,
    fundingFilter,

    resumeFilter,
    coeFilter,
    submissionFilter,

    //

    statusOptions,
    foresightOptions,
    visibilityOptions,
    fundingSourceOptions,
    paymentStatusOptions,
    submittedToVaOptions,
    uploadedCoeOptions,
    uploadedResumeOptions,
    examVoucherOptions,
    yesNoOptions,
    courseStatusOptions,
    locationOptions,
  };
}
