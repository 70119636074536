<template>
  <b-sidebar
    id="add-new-profile-sidebar"
    :visible="isAddNewProfileSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-profile-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Profile</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(createRosterProfile)" @reset.prevent="resetForm">
          <!-- is existing student -->
          <validation-provider #default="validationContext" name="Existing Student?" rules="required">
            <b-form-group
              label="Existing Student?"
              label-for="isExistingStudent"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="isExistingStudent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false, disabled: true },
                ]"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="isExistingStudent"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="isExistingStudent">
            <validation-provider #default="validationContext" name="Existing Student?" rules="required">
              <b-form-group label="Student" label-for="student">
                <vue-autosuggest
                  id="student"
                  ref="autocomplete"
                  v-model="studentQuery"
                  :suggestions="studentSuggestions"
                  :input-props="studentInputProps"
                  :section-configs="studentSectionConfigs"
                  :render-suggestion="studentRenderSuggestion"
                  :get-suggestion-value="studentGetSuggestionValue"
                  @input="studentFetchResults"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Pathway -->
            <validation-provider #default="validationContext" name="Pathway" v-if="!profileData.subject">
              <b-form-group label="Pathway" label-for="pathway" :state="getValidationState(validationContext)">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Preferred Pathway"
                  :value="profileData.pathway"
                  :options="pathwaySuggestions"
                  class="w-100"
                  :reduce="(val) => val.value"
                  :clearable="true"
                  @search="searchForPathway"
                  @input="(val) => (profileData.pathway = val)"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subject -->
            <validation-provider #default="validationContext" name="Subject" v-if="!profileData.pathway">
              <b-form-group label="Subject" label-for="Subject" :state="getValidationState(validationContext)">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Preferred Subject"
                  :value="profileData.subject"
                  :options="subjectSuggestions"
                  class="w-100"
                  :reduce="(val) => val.value"
                  :clearable="true"
                  @search="searchForSubject"
                  @input="(val) => (profileData.subject = val)"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Pathway Date -->
            <validation-provider #default="validationContext" name="Preferred Start Date" rules="required">
              <b-form-group
                label="Preferred Start Date"
                label-for="pathwayDate"
                :state="getValidationState(validationContext)"
              >
                <b-form-datepicker id="pathwayDate" v-model="profileData.pathwayDate" class="mb-1" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Funding Source -->
            <validation-provider #default="validationContext" name="Funding Source" rules="required">
              <b-form-group label="Funding Source" label-for="fundingSource" :state="getValidationState(validationContext)">
                <v-select
                  v-model="profileData.attributes.fundingSource"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="fundingSourceOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="fundingSource"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider #default="validationContext" name="Status" rules="required">
              <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
                <v-select
                  v-model="profileData.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="profile-status"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <div v-else class="text-center">
            Creating a roster profile is not currently supported without an existing student profile.

            <br /><br />

            <b-link :to="{ name: 'apps-students-list', query: { op: 'create', return: 'apps-roster-profile-list' } }">
              Click here to create a student profile
            </b-link>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button-group class="w-100">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="hide">
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :disabled="!isExistingStudent"
                @click="createRosterProfile(false)"
              >
                Create
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :disabled="!isExistingStudent"
                @click="createRosterProfile(true)"
              >
                Create + Edit
              </b-button>
            </b-button-group>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BButtonGroup,
  BLink,
  BFormDatepicker,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { onUnmounted, ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { VueAutosuggest } from 'vue-autosuggest';
import useProfilesList from '@/views/apps/roster/profile-list/useProfilesList';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import { useRouter } from '@core/utils/utils';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BButtonGroup,
    BLink,
    BFormDatepicker,
    vSelect,
    VueAutosuggest,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProfileSidebarActive',
    event: 'update:is-add-new-profile-sidebar-active',
  },
  props: {
    isAddNewProfileSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isExistingStudent: false,
      student: {},

      required,
      alphaNum,
      email,
    };
  },
  methods: {
    studentFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('app-student/fetchStudents', {
          search: this.studentQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.studentSuggestions = [];

          const users = values[0].data.results;

          users.length && this.studentSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    studentRenderSuggestion(row) {
      return `${row.item.studentId} ${row.item.fullName}`;
    },
    studentGetSuggestionValue(row) {
      if (row.item) {
        return this.studentRenderSuggestion(row);
      }
      return `${row.studentId} ${row.fullName}`;
    },

    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchPathways', { search, limit: 100 });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: id, label: name };
      });
    },

    subjectRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    subjectGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForSubject(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchSubjects', { search, limit: 100 });
      const subjects = response.data.results;

      this.subjectSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },

    async createRosterProfile(follow) {
      if (!this.studentSelected || !this.studentSelected.id) {
        console.log(`invalid student`);
        return false;
      }

      const payload = {
        student: this.studentSelected.id,
        pathway: this.profileData.pathway,
        subject: this.profileData.subject,
        pathwayDate: this.profileData.pathwayDate,
        attributes: this.profileData.attributes,
        status: this.status,
      };

      store
        .dispatch('app-roster/addProfile', payload)
        .then((response) => {
          console.log(`created profile`, response);

          const { data: profile } = response;

          if (follow) {
            return this.$router.push({
              name: 'apps-roster-profile-edit',
              params: { id: profile.id },
            });
          }

          if (this.$route.query.return) {
            return this.$router.push({
              name: this.$route.query.return,
              params: { id: payload.student },
              query: {
                op: 'session.assign',
                profileId: profile.id,
              },
            });
          }

          this.$emit('refetch-data');
        })
        .catch((error) => {
          console.error(`failed to create profile`, error);
        })
        .finally(() => {
          this.$emit('update:is-add-new-profile-sidebar-active', false);
        });
    },
  },
  mounted() {
    const { studentId } = this.$route.query;

    this.searchForPathway('');
    this.searchForSubject('');

    if (studentId) {
      store
        .dispatch('app-student/fetchStudent', {
          id: studentId,
        })
        .then((response) => {
          this.isExistingStudent = true;
          this.studentQuery = this.studentGetSuggestionValue(response.data);
          this.studentSelected = response.data;
          this.profileData.student = response.data;
        });
    }
  },
  setup(props, { emit }) {
    const blankProfileData = {
      name: '',
      student: {},
      attributes: {
        fundingSource: 'open',
      },
      status: 'pending',
    };

    const { pathway: qPathway, subject: qSubject } = router.currentRoute.query;

    if (qPathway) {
      blankProfileData.pathway = qPathway;
    }

    if (qSubject) {
      blankProfileData.subject = qSubject;
    }

    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME))
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
    });

    const profileData = ref(JSON.parse(JSON.stringify(blankProfileData)));
    const resetprofileData = () => {
      profileData.value = JSON.parse(JSON.stringify(blankProfileData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetprofileData);

    const studentQuery = ref('');
    const studentSuggestions = ref([]);
    const studentSelected = ref({});
    const studentSectionConfigs = {
      users: {
        limit: 10,
        label: 'Students',
        onSelected: (selected) => {
          studentSelected.value = selected.item;
        },
      },
    };

    const studentInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for students',
      class: 'form-control',
      name: 'ajax',
    };

    const {
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
      paymentStatusOptions,
      submittedToVaOptions,
      uploadedCoeOptions,
      uploadedResumeOptions,
    } = useProfilesList();

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          profileData.value.pathway = selected.item;
        },
      },
    };

    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };

    const subjectQuery = ref('');
    const subjectSuggestions = ref([]);
    const subjectSelected = ref(undefined);
    const subjectSectionConfigs = {
      subjects: {
        limit: 10,
        label: 'Subjects',
        onSelected: (selected) => {
          subjectSelected.value = selected.item;
          profileData.value.subject = selected.item;
        },
      },
    };

    const subjectInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for subjects',
      class: 'form-control',
      name: 'ajax',
    };

    return {
      profileData,
      // onSubmit,

      statusOptions,

      studentQuery,
      studentSuggestions,
      studentSectionConfigs,
      studentInputProps,
      studentSelected,

      refFormObserver,
      getValidationState,
      resetForm,

      //
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,
      subjectQuery,
      subjectSuggestions,
      subjectSectionConfigs,
      subjectInputProps,
      subjectSelected,

      fundingSourceOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}

#add-new-profile-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
